const backgroundPaper = (theme) => theme.palette.background.paper;

const flexOne = 1;

export const rightBoxWrapper = {
  flex: flexOne,
  ...backgroundPaper,

  "@media (min-width: 769px)": {
    flex: "0 0 50%",
  },
};

export const contentWrapper = {
  p: "2.4rem",

  "@media (min-width: 769px)": {
    maxWidth: "79.2rem",
    p: "16.4rem 12rem 10.8rem 4.8rem",
  },
};
