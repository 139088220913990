import { useRouter } from "next/router";
import { EONBOARDING_STATUS } from "../enums/onboarding-status.enum";
import { EAPP_ROUTE } from "../enums/route.enum";
import { onboardingRoutes } from "../utils/onboarding-routes";
import { useRouteHistory } from "../contexts/history-context";

export const useLoginSwitchRoute = () => {
  const router = useRouter();
  const { history } = useRouteHistory();
  const loginSwitchRoute = (onboardingStatus: EONBOARDING_STATUS) => {
    const isOnboarded =
      onboardingStatus === EONBOARDING_STATUS.ONBOARDED ? true : false;
    const previousRoute = history[history.length - 2];
    if (isOnboarded) {
      /*if (router.query && router.query.next) {
        router.back();
      } else {*/

      router.push(previousRoute ? previousRoute : EAPP_ROUTE.MAIN);
      //}
    } else {
      router.push(onboardingRoutes[onboardingStatus]);
    }
  };
  return loginSwitchRoute;
};
