export const AppRegex = {
  digitGreterThan1000: /^[1-9][0-9]{3}$/,
  number: /^-?\d*(\.\d+)?$/,
  integer: /^\d+$/,
  mobile: /^((\+|00)?\d{1,3})?[1-9][0-9]{9}$/,
  phone: /^([+]?[(]?[0-9]{3}[)]?)?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im,
  areaCode: /^[+]?[(]?[0-9]{1,4}[)]?/,
  website:
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9]\.[^\s]{2,})/,
  smsCode: /^\d{4}$/,
  organizationNumber: /^[0-9]*$/i,
  email: /^((?!\.)[\w\-\._+]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/i,
  strongPassword: /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
  url: /((https?):\/\/)?(www\.)?[a-z0-9\-._~:&?#@!$'()*+,;=%]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#%&-]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
  nameValidation: /^\p{L}+(?:[ '-]\p{L}+)*$/u,
};
