import { IUser, TUpdateUser } from "../model/user.model";
import client from "../utils/client";

export default class CurrentUserService {
  prefix = "user-management/user";

  get = (): Promise<Types.RequestResponse<IUser>> =>
    client.get(`${this.prefix}`);

  getDetails = () => client.get(`${this.prefix}/details`);

  update = (data: TUpdateUser): Promise<Types.RequestResponse<IUser>> =>
    client.update(`${this.prefix}`, data);

  changePassword = (
    data: Types.IChangePassword
  ): Promise<Types.RequestResponse<{}>> =>
    client.create(`${this.prefix}/new-password`, data);
}
