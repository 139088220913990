import NumberFormat, { NumberFormatProps } from "react-number-format";
import {
  IconButton,
  InputAdornment,
  OutlinedTextFieldProps,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { Visibility, VisibilityOff, ContentCopy } from "@mui/icons-material";
import React from "react";

interface NumberProps extends NumberFormatProps {}

interface IFormInputProps extends OutlinedTextFieldProps {
  numberFormat?: NumberProps;
  copyField?: boolean;
  maxLength?: number;
}
export const AppInput: React.FC<IFormInputProps> = (props: IFormInputProps) => {
  return (
    <TextField
      data-testid={props.label || props.name}
      InputProps={{
        inputComponent: props.numberFormat
          ? (NumberFormatCustom as any)
          : undefined,
        inputProps: {
          ...props.numberFormat,
          "data-testid": `__${props.label || props.name}__`,
          maxLength: props.maxLength,
        },
        endAdornment: props.copyField && (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() =>
                navigator.clipboard.writeText((props.value as string) || "")
              }
            >
              <ContentCopy />
            </IconButton>
          </InputAdornment>
        ),
        ...props.InputProps,
      }}
      {...props}
    />
  );
};
export const AppInputPassword: React.FC<IFormInputProps> = (
  props: IFormInputProps
) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  return (
    <TextField
      data-testid={props.label || props.name}
      InputProps={{
        inputComponent: props.numberFormat
          ? (NumberFormatCustom as any)
          : undefined,
        inputProps: {
          ...props.numberFormat,
          "data-testid": `__${props.label || props.name}__`,
        },
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),

        ...props.InputProps,
      }}
      {...props}
      type={showPassword ? "text" : "password"}
    />
  );
};
interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}
//@ts-ignore
const NumberFormatCustom = React.forwardRef<NumberFormat, CustomProps>(
  function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        isNumericString
        prefix=""
      />
    );
  }
);
