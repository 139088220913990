import { Box } from "@mui/material";
import { ReactNode } from "react";
import { contentWrapper, rightBoxWrapper } from "./styles";

interface IRightBox {
  children: ReactNode;
}

const RightBox = ({ children }: IRightBox) => {
  return (
    <Box sx={rightBoxWrapper}>
      <Box sx={contentWrapper}>{children}</Box>
    </Box>
  );
};

export default RightBox;
