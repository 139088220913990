import NextLink from "next/link";
import { useTranslation } from "react-i18next";
import { Box, Link } from "@mui/material";
import { LoginForm } from "./login-form";
import VerifiedEmailIcon from "../../../public/img/onboarding/email-verified.svg";
import { EAPP_ROUTE } from "../../enums/route.enum";
import { componentWrapper, displayFlexCenter } from "./styles";

export const VerifiedEmailLogin = () => {
  const { t } = useTranslation();

  return (
    <>
      <Box sx={componentWrapper}>
        <Box margin=".8rem 0 1.6rem 0">
          <VerifiedEmailIcon />
        </Box>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          mt: 3,
        }}
      >
        <LoginForm />
      </Box>
      <Box
        sx={{
          ...displayFlexCenter,
          mb: 3,
          mt: 3,
        }}
      >
        <NextLink href={EAPP_ROUTE.FORGOT_PASSWORD} passHref>
          <Link
            color="primary"
            variant="body2"
            data-testid="login_page__forgot_password"
          >
            {t("login_page__forgot_password")}
          </Link>
        </NextLink>
      </Box>
    </>
  );
};
