export const displayFlexCenter = {
  display: "flex",
  justifyContent: "center",
};

export const componentWrapper = {
  alignItems: "center",
  flexDirection: "column",
  ...displayFlexCenter,
};
