import { useRouter } from "next/router";

import { VerifiedEmailLogin } from "../../components/login/verified-email-login";
import { LoginComponent } from "../../components/login";
import RightBox from "../../components/layout/login/right-box/RightBox";
import SignUpLayout from "../../components/layout/login/sign-up/SignUpLayout";
import LeftSideMessage from "../../components/login/left-message/LeftSideMessage";

const Login = () => {
  const router = useRouter();
  const verified = router?.query?.verified;

  return (
    <SignUpLayout>
      <LeftSideMessage
        title={
          verified ? "login_page__account_verified_title" : "login_page__login"
        }
        message={
          verified
            ? "login_page__you_can_login_to_your_account"
            : "login_page__login_no_account"
        }
      />
      <RightBox>
        {verified ? <VerifiedEmailLogin /> : <LoginComponent />}
      </RightBox>
    </SignUpLayout>
  );
};

export default Login;
