import { useEffect, useRef, useState } from "react";
import config from "../../utils/config";
import ReCAPTCHA from "react-google-recaptcha";

interface IProps {
  handleRef: any;
  handleValue: any;
}

const GoogleCaptcha = (props: IProps) => {
  const [changes, setChanges] = useState(null);
  const captchaRef = useRef(null);
  useEffect(() => {
    props.handleValue(changes);
    props.handleRef(captchaRef);
  }, [changes]);
  return (
    <ReCAPTCHA
      sitekey={config.GOOGLE_CAPTCHA}
      ref={captchaRef}
      size="invisible"
      onChange={setChanges}
    />
  );
};

export default GoogleCaptcha;
