import { Alert, Box, Link } from "@mui/material";
import React, { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useMutation } from "react-query";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import NextLink from "next/link";
import AuthService from "../../service/auth.service";
import CurrentUserService from "../../service/current-user.service";
import { setUserAction } from "../../redux/action/user";
import { setToken } from "../../service/service-utils/service-utils";
import { ILogin } from "../../model/login.model";
import { IUser } from "../../model/user.model";
import { AppInput, AppInputPassword } from "../share/app-input";
import AppButton from "../share/app-button";
import { useIsStartedOnboarding } from "../../hooks/use-onboarding";
import { useLoginSwitchRoute } from "../../hooks/use-login-switch-route";
import { getOutlets } from "../../hooks/use-set-outlets";
import { EAPP_ROUTE } from "../../enums/route.enum";
import { AppRegex } from "../../utils/regex";
import { apiRequestHandler } from "../../api/requestHandler";
import GoogleCaptcha from "../share/google-captcha";

export const LoginForm: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const isStartedOnboarding = useIsStartedOnboarding();
  const { t } = useTranslation();
  const authService = new AuthService();
  const router = useRouter();
  const switchRoute = useLoginSwitchRoute();
  const currentUserService = new CurrentUserService();
  const [formErrors, setFormErrors] = useState<string>("");
  const [emailIsVerified, setEmailIsVerified] = useState<boolean>(false);
  const [captchaRef, setCaptchaRef] = useState<any>(null);
  const [captchaValue, setCaptchaValue] = useState<any>(null);

  const loginMutation = useMutation(
    async (formData: Types.LoginRequest) => {
      setFormErrors("");
      const {
        data,
        hasError,
        error,
        errorData,
      }: Types.RequestResponse<ILogin> = await authService.login(formData);

      if (hasError) {
        if (errorData?.error_code === "email.not-verified") {
          setFormErrors(t("login_page__error_message__email_is_not_verified"));
          setEmailIsVerified(false);
        } else if (error && error >= 400 && error <= 499) {
          setEmailIsVerified(true);
          setFormErrors(
            t("login_page__error_message__username_or_password_is_incorrect")
          );
        }
        throw error;
      } else {
        if (data) {
          setToken(data.token);
          const first =
            localStorage.getItem("firstExperience") !== null
              ? localStorage.getItem("firstExperience")
              : "true";
          localStorage.setItem(
            "lastActivity",
            JSON.stringify(new Date().toISOString())
          );
          localStorage.setItem(
            "lastLogin",
            JSON.stringify(new Date().toISOString())
          );
          localStorage.setItem("firstExperience", first!);
        }
      }
    },
    {
      onSuccess: () => {
        userMutation.mutate();
      },
    }
  );
  const userMutation = useMutation(
    async (): Promise<IUser> => {
      const { data, hasError, error }: Types.RequestResponse<IUser> =
        await currentUserService.get();
      if (hasError) {
        throw error;
      }

      const accounts: any = await apiRequestHandler(
        `api/hospitad/user-management/accounts`,
        "GET",
        {},
        {
          "Content-Type": "application/json",
        }
      );
      if (data && accounts) {
        const user = { ...data, accounts: accounts };
        dispatch(setUserAction(user));

        !isStartedOnboarding
          ? router.query && router.query.outletId
            ? getOutlets({ outletId: router.query.outletId as string })
            : getOutlets()
          : null;
        //@ts-ignore
        window?.ReactNativeWebView?.postMessage(
          JSON.stringify({
            id: data?.id,
            userToken: localStorage.getItem("accessToken"),
          })
        );
      }

      return data!;
    },
    {
      onSuccess: (userData: IUser) => {
        userData &&
          userData.onboardingStatus &&
          switchRoute(userData.onboardingStatus);
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .matches(
          AppRegex.email,
          t("login_page__error_message__email_is_not_verified")
        )
        .required(t("login_page__error_message__email_is_required")),
      password: Yup.string().required(
        t("login_page__error_message__password_is_required")
      ),
    }),

    onSubmit: (value) => {
      loginMutation.mutate({ ...value, captchaToken: captchaValue });
      captchaRef?.current?.reset();
      setCaptchaValue(null);
    },
  });

  return (
    <form
      noValidate
      onSubmit={formik.handleSubmit}
      onChange={() => captchaRef?.current?.execute()}
    >
      <AppInput
        variant="outlined"
        autoFocus
        error={Boolean(formik.touched.email && formik.errors.email)}
        fullWidth
        helperText={formik.touched.email && formik.errors.email}
        label={t("login_page__email_address")}
        margin="normal"
        name="email"
        onChange={formik.handleChange}
        // onBlur={formik.handleBlur}   // Disabling this will stop the validation process for this form
        type="email"
        value={formik.values.email}
      />
      <AppInputPassword
        variant="outlined"
        error={Boolean(formik.touched.password && formik.errors.password)}
        fullWidth
        helperText={formik.touched.password && formik.errors.password}
        label={t("login_page__password")}
        margin="normal"
        name="password"
        onChange={formik.handleChange}
        // onBlur={formik.handleBlur}   // Disabling this will stop the validation process for this form
        type="password"
        value={formik.values.password}
      />
      {formErrors && (
        <Box sx={{ mt: 3 }}>
          <Alert severity="error">
            <div>
              <b>{formErrors}</b>{" "}
              {!emailIsVerified ? (
                <NextLink
                  href={`${EAPP_ROUTE.VERIFY}?email=${formik.values.email}`}
                  passHref
                >
                  <Link
                    color="primary"
                    variant="body2"
                    data-testid="verify-email-link"
                    sx={{ textDecoration: "underline" }}
                  >
                    {t("login_page__verify_email")}
                  </Link>
                </NextLink>
              ) : null}
            </div>
          </Alert>
        </Box>
      )}

      <Box sx={{ mt: 2 }}>
        <GoogleCaptcha
          handleValue={setCaptchaValue}
          handleRef={setCaptchaRef}
        />
      </Box>
      <Box sx={{ mt: 2 }}>
        <AppButton
          fullWidth
          size="large"
          type="submit"
          data-testid="login-button"
          variant="contained"
          color="secondary"
          disabled={Boolean(
            formik.values.email === "" || formik.values.password === ""
          )}
          loading={loginMutation.isLoading || userMutation.isLoading}
        >
          {t("login_page__login")}
        </AppButton>
      </Box>
      <Box sx={{ mt: 2 }}></Box>
    </form>
  );
};
